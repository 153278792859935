@import '../../../styles/common/media_queries';

.learning_path_wrapper {
  overflow-y: auto;

    @include media_query(XS) {
      // margin-top: 25px;
    }

    @include media_query(M) {
      // padding-left: 65px;
      padding-top: 150px;
      margin-top: 0;
    }

    @include media_query(L) {
      padding-top: 150px;
      // padding-left: 65px;
    }
}
.course_wizard_container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 3;
  background-color: #fff;
  // Adapt file scss
  .prev_adapt_file_wrapper {
    display: none;
    position: fixed;

    z-index: 12;
    cursor: pointer;
    @include media_query(XS) {
      position: fixed;
      display: flex;
      justify-content: center;
      top: auto;
      right: initial;
      left: 0;
      height: auto;
      bottom: 16px;
      margin: auto;
      width: 100%;
    }
    @include media_query(M) {
      display: flex;
      align-items: center;
      justify-content: center;
      left: initial;
      top: 70px;
      right: 95px;
      width: 200px;
      height: 40px;
      margin: initial;
    }
    .txt {
      font-family: 'SourceSansPro';
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #2c2c2c;
      margin: 0 10px;
    }
    .txt_disable {
      font-family: 'SourceSansPro';
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: #707070;
      margin: 0 10px;
    }
    .adapt_file_icon {
      width: 15px;
      height: 16px;
      background-image: url(../../../styles/images/adapt_file_con.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    .adapt_file_icon_disabled {
      width: 15px;
      height: 16px;
      background-image: url(../../../styles/images/disablebPreviewIcon.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  // Review & Publish
  .category_wrapper {
    &.review_publish_wrapper {
      @include media_query(XS) {
        height: calc(100vh - 225px) !important;
      }
      @include media_query(M) {
        height: calc(100vh - 185px) !important;
      }
      .category_inner_wrapper {
        .review_publish_lp {
          .error_review_publish {
            .card {
              background: #fdf4f4;
              border: 1px solid #eb8593;
              .status_icon {
                background-image: url(../../../styles/images/error_icon_red.svg);
              }
              .go_page {
                color: #d91734;
                border-bottom: 2px solid #d91734;
              }
              .details_wrapper {
                .title {
                  color: #d91734;
                }
                .sub_title {
                  color: #d91734;
                }
              }
            }
          }
        }
        .review_publish_lp {
          .success_review_publish {
            .card {
              background: #f0fae5;
              border: 1px solid #7fbc96;
              .status_icon {
                background-image: url(../../../styles/images/success_icon.svg);
              }
              .go_page {
                color: #107f47;
                border-bottom: 2px solid #107f47;
              }
              .details_wrapper {
                width: calc(100% - 25px);
                .title {
                  color: #107f47;
                }
                .sub_title {
                  color: #107f47;
                }
              }
            }
          }
        }
        .review_publish_lp {
          .title {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 700;
            color: #2c2c2c;
            margin-bottom: 16px;
          }
          .card_wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -8px;
          }
          .card_gutters {
            padding: 0 8px;
          }
          .card {
            display: flex;
            align-items: flex-start;
            border-radius: 12px;
            padding: 10px;
            margin-bottom: 16px;
            .status_icon {
              width: 21px;
              height: 18px;
              background-size: contain;
              background-repeat: no-repeat;
            }
            .go_page {
              font-family: 'SourceSansPro';
              font-size: 14px;
              font-weight: 700;
              margin: auto;
              cursor: pointer;
            }
            .details_wrapper {
              width: calc(100% - 100px);
              padding: 0 10px 0 8px;
              .title {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                line-height: 1;
                margin-bottom: 5px;
              }
              .sub_title {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 400;
              }
            }
          }

          &.decommission_section {
            margin-top: 24px;
            .title_wrapper {
              display: flex;
              align-items: center;
              margin-bottom: 16px !important;
              cursor: pointer;
              .title {
                font-family: 'SourceSansPro';
                font-weight: 700;
                line-height: 1;
                margin-bottom: 0px !important;
                padding-left: 12px;
                @include media_query(XS) {
                  font-size: 20px !important;
                }
                @include media_query(M) {
                  font-size: 24px;
                }
              }
              .danger_icon {
                width: 18px;
                height: 24px;
                background-image: url(../../../styles/images/danger_icon.svg);
                background-repeat: no-repeat;
              }
              .arrow {
                width: 17px;
                height: 12px;
                background-repeat: no-repeat;
                margin-left: auto;
                background-image: url(../../../styles/images/down-arrow-slim.svg);
                cursor: pointer;
                &.down_arrow {
                  transform: rotate(0deg);
                }
                &.up_arrow {
                  transform: rotate(180deg);
                }
              }
            }
            .description {
              font-family: 'SourceSansPro';
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 16px;
            }
            .btn {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 270px;
              width: fit-content;
              height: 36px;
              cursor: pointer;
              .text {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #d91734;
                padding-left: 8px;
              }
              .delete_icon {
                width: 16px;
                height: 15px;
                background-image: url(../../../styles/images/archive_box_red.svg);
                background-repeat: no-repeat;
              }
              &.archive {
                border: 2px solid #d91734;
                border-radius: 24px;
              }
            }
          }
        }
      }
    }
  }
  // Notification bar code here
  .notification_wrapper {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 450px;
    height: auto;
    z-index: 5;
    @include media_query(XS) {
      width: fit-content;
    }
    @include media_query(M) {
      width: 450px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }

    .noti_bar {
      width: 100%;
      min-height: 60px;

      border-radius: 12px;
      padding: 12px 35px;
      position: relative;

      &.success_bar {
        background-color: #f0fae5;
        border: 1px solid #7fbc96;
      }
      &.failure_bar {
        background-color: #fff9f0;
        border: 1px solid #d1b278;
      }
      // New class added for in-progress notification
      &.inprogress_bar {
        background-color: #f1f2ff;
        border: 1px solid #94a3cd;
      }
      .text_wrapper {
        &.success_txt {
          color: #107f47;
        }
        &.failure_txt {
          color: #a36a00;
        }
        &.inprogress_text {
          color: #38539a;
        }
        .tagline {
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 700;
          line-height: 18px;
        }
        .description {
          font-family: 'SourceSansPro';
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
        }
      }
      .left_icon {
        position: absolute;
        top: 12px;
        left: 10px;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        &.success_tick_icon {
          background-image: url(../../../styles/images/success_icon.svg);
        }
        &.failure_icon {
          width: 18px;
          background-image: url(../../../styles/images/failure_icon.svg);
        }
        &.inprogress_icon {
          width: 16px;
          height: 18px;
          background-image: url(../../../styles/images/inprogress_up_icon.svg);
          background-size: contain;
        }
      }

      .right_icon {
        position: absolute;
        top: 12px;
        right: 10px;
        width: 10px;
        height: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        &.green_close_icon {
          background-image: url(../../../styles/images/close_green_icon.svg);
        }
        &.red_close_icon {
          // background-image: url(../../../styles/images/close_red_icon.svg);
          background-image: url(../../../styles/images/close_red_icon.svg);
        }
        &.blue_close_icon {
          background-image: url(../../../styles/images/cross_blue_icon.svg);
        }
      }
    }
  }
  // Notification bar End

  .lp_wizard_wrapper {
    overflow-y: auto;

    @include media_query(XS) {
      // margin-top: 25px;
    }

    @include media_query(M) {
      padding-left: 65px;
      padding-top: 170px;
      margin-top: 0;
    }

    @include media_query(L) {
      padding-top: 130px;
      padding-left: 65px;
    }

    .inner_wrapper {
      @include media_query(XS) {
        margin-left: initial;
      }
      @include media_query(M) {
        margin-left: -60px;
      }
      &.full_height {
        height: calc(100vh - 180px);
      }
      &.preview_adapt {
        @include media_query(XS) {
          height: calc(100vh - 215px);
        }
      }
      &.adapt_file {
        height: calc(100vh - 205px);
        padding-bottom: 90px;
        @include media_query(M) {
          height: calc(100vh - 155px);
        }
        @include media_query(L) {
          height: calc(100vh - 205px);
        }
      }

      &.empty_course_category {
        @include media_query(XS) {
          padding-bottom: 0;
        }
        @include media_query(M) {
          padding-bottom: 40px;
        }
      }
      &.padding_class {
        @include media_query(XS) {
          padding-top: 24px;
          padding-bottom: 70px;
        }
        @include media_query(M) {
          padding-top: initial;
          padding-bottom: initial;
        }
      }
      &.learning_path_description {
        @include media_query(XS) {
          padding-top: 0px;
          padding-bottom: 70px;
        }
        @include media_query(M) {
          padding-top: initial;
          padding-bottom: initial;
        }
      }
      @include media_query(XS) {
        padding: 0 15px;
        height: calc(100vh - 185px);
        overflow-y: auto;
        padding-bottom: 70px;
      }

      @include media_query(M) {
        height: auto;
        padding: 40px;
      }
      &.padding_class {
        @include media_query(M) {
          padding-top: 40px;
        }
      }
      .row_wrapper {
        @include media_query(XS) {
          margin: 0 -15px;
        }
        @include media_query(M) {
          margin: 0 -15px;
        }
        &.lp_description_row_wrapper {
          @include media_query(XS) {
            margin: 0 -15px;
          }
          @include media_query(M) {
            margin: 0 -30px;
          }
        }
        .preview_tab_wrapper {
          background-color: #f1f2ff;
          border: 1px solid #94a3cd;
          border-radius: 12px;
          padding: 10px;
          display: flex;
          align-items: center;
          min-height: 36px;
          margin-bottom: 16px;

          .preview_icon {
            background-image: url(../../../styles/images/eye_icon_blue.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 12px;
            margin-right: 8px;
          }
          .note_txt {
            font-family: 'SourceSansPro';
            font-size: 14px;
            color: #38539a;
            font-weight: 500;
            line-height: 18px;
          }
        }

        .info_container {
          display: flex;
          flex-direction: column;
          @include media_query(M) {
            flex-direction: column;
          }
          @include media_query(ML) {
            flex-direction: row;
          }
          @include media_query(L) {
            flex-direction: row;
          }
          &.lp_description {
            margin-bottom: 16px !important;
            .inr_wrapper {
              margin-bottom: 0 !important;
              .txt {
                line-height: initial !important;
                min-height: fit-content !important;
              }
            }
          }
          &.category_inner_wrapper {
            .inr_wrapper {
              &.margin_pad_x {
                margin-bottom: 20px !important;
              }
            }
          }
          .pills_add_wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 -6px;
            .pills {
              display: flex;
              align-items: center;
              min-width: 45px;
              height: 32px;
              padding: 4px 8px 4px 12px;
              border: 1px solid #2c2c2c;
              border-radius: 24px;
              margin: 0 6px;
              margin-bottom: 12px;
              cursor: pointer;
              &.selected {
                border: 1px solid #38539a;
                background: #38539a;
                .pill_img {
                  &.dollar {
                    width: 15px;
                    height: 14px;
                    background-image: url(../../../styles/images/dollar-white-icon.svg);
                    background-size: contain;
                  }
                }
                .pill_txt {
                  color: #fff;
                }
                .pill_add {
                  &.add_circle {
                    background-image: url(../../../styles/images/minus-circle-white.svg);
                  }
                }
              }
              .pill_img {
                background-repeat: no-repeat;
                background-size: cover;
                &.dollar {
                  width: 15px;
                  height: 14px;
                  background-image: url(../../../styles/images/dollar-icon.svg);
                  background-size: contain;
                }
                &.mobile {
                  width: 15px;
                  height: 13px;
                  background-image: url(../../../styles/images/mobile-icon.svg);
                  background-size: contain;
                }
                &.shopping_cart {
                  width: 13px;
                  height: 12px;
                  background-image: url(../../../styles/images/shopping-cart-icon.svg);
                  background-size: contain;
                }
                &.hash {
                  width: 15px;
                  height: 14px;
                  background-image: url(../../../styles/images/hash-icon.svg);
                }
                &.people {
                  width: 19px;
                  height: 13px;
                  background-image: url(../../../styles//images/people.svg);
                }
              }
              .pill_txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 400;
                color: #2c2c2c;
                padding: 0 6px 0 6px;
              }
              .pill_add {
                &.add_circle {
                  width: 16px;
                  height: 16px;
                  background-image: url(../../../styles/images/add-circle-black.svg);
                  background-repeat: no-repeat;
                }
                // &.minus_circle {
                //   width: 16px;
                //   height: 16px;
                //   background-image: url(../../../styles/images/minus-circle-white.svg);
                //   background-repeat: no-repeat;
                // }
              }
            }
          }
          &.skills_inner_wrapper,
          &.category_inner_wrapper {
            @include media_query(XS) {
              flex-direction: column;
            }
            .form_outer_field {
              margin: 0 -15px;
            }
            .inr_wrapper {
              .title_wrapper {
                .txt {
                  @include media_query(XS) {
                    min-height: fit-content;
                    line-height: initial;
                  }
                }
              }
            }

            .category_list_wrapper {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 12px;
              // margin: 0 -8px;
              .list {
                font-family: 'SourceSansPro';
                font-size: 16px;
                font-weight: 700;
                color: #2c2c2c;
                padding: 0 8px;
                // margin-bottom: 12px;
                border: 1px solid #2C2C2C;
                border-radius: 4px;
              }
            }

            .down_arw_icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
              width: 17px;
              height: 12px;
              background-image: url(../../../styles/images/down_arrow_2.svg);
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer;
            }
            .down_arw_icon_open {
              position: absolute;
              top: 50%;
              transform: translateY(-50%) rotate(180deg);
              right: 10px;
              width: 17px;
              height: 12px;
              background-image: url(../../../styles/images/down_arrow_2.svg);
              background-repeat: no-repeat;
              background-size: contain;
              cursor: pointer;
            }
            .tags_inherited_wrapper {
              position: relative;
              display: flex;
              align-items: flex-start;
              min-height: 48px;
              background: #f4f4f4;
              padding: 16px;
              border-radius: 10px;
              flex-direction: column;
              @include media_query(XS) {
                margin: 0 16px 0px 16px;
              }
              @include media_query(M) {
                margin: 0;
              }
              .row_wrapper_margin {
                margin: 0 -15px;
              }
              .collapsible_wrapper {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                flex-wrap: nowrap;
                .text {
                  font-family: 'SourceSansPro';
                  font-size: 18px;
                  font-weight: 500;
                  color: #2c2c2c;
                }
                .down_arw_icon {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 16px;
                  width: 20px;
                  height: 13px;
                  background-image: url(../../../styles/images/down_arrow_black.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor: pointer;
                }
                .down_arw_icon_open {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%) rotate(180deg);
                  right: 16px;
                  width: 20px;
                  height: 13px;
                  background-image: url(../../../styles/images/down_arrow_black.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor: pointer;
                }
              }
            }
          }
          .inr_wrapper {
            padding: 0 15px;
            // padding-top: 25px;
            margin-bottom: 25px;

            .tag_text {
              font-family: 'SourceSansPro';
              font-size: 14px;
              color: #707070;
              display: flex;
              align-items: center;
              justify-content: end;

              @include media_query(M) {
                font-size: 16px;
              }
              @include media_query(L) {
                width: auto;
              }
              &.padding_zero {
                padding-top: 0;
              }
            }
            &.margin_zero {
              padding-top: 25px;
              margin-bottom: 15px;
              @include media_query(M) {
                margin-bottom: 0;
                padding-top: 0;
              }
            }
            &.margin_pad_x {
              padding-top: 25px;
              // margin-bottom: 15px;
              @include media_query(M) {
                margin-bottom: 40px !important;
                padding-top: 0;
              }
            }
            @include media_query(M) {
              padding: 0 40px;
            }
            @include media_query(L) {
              margin-bottom: 40px;
              &:first-child {
                margin-bottom: 25px;
              }
            }
            .title_wrapper {
              margin-bottom: 12px;
              &.top_padding {
                padding-top: 28px;
              }
              &.margin_zero {
                @include media_query(XS) {
                  margin-bottom: 0;
                }
              }
              .title {
                font-family: 'SourceSansPro';
                font-size: 21px;
                font-weight: 700;
                color: #2c2c2c;
                line-height: 24px;
                margin-bottom: 8px;
                @include media_query(M) {
                  font-size: 24px;
                  line-height: 27px;
                  margin-bottom: 10px;
                }
              }
              .sub_title {
                font-size: 16px;
                font-weight: 700;
                color: #2C2C2C;
                line-height: 24px;
              }
              .txt {
                font-family: 'SourceSansPro';
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: #2c2c2c;
                min-height: 48px;
                &.margin_zero {
                  @include media_query(L) {
                    margin-bottom: 0;
                  }
                }
                @include media_query(M) {
                  font-size: 16px;
                  line-height: 24px;
                  margin-bottom: 20px;
                }
              }
            }

            .btn_wrapper_uaf {
              width: 100%;
              height: 35px;
              background-color: #d9d9d9;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 24px;
              margin-bottom: 15px;
              cursor: pointer;
              &.margin_zero {
                @include media_query(XS) {
                  margin-bottom: 0;
                }
              }
              .txt_replace {
                font-family: 'SourceSansPro';
                color: #5e5e5e;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                margin-right: 10px;
              }

              @include media_query(M) {
                height: 48px;
              }

              &.btn_wrapper_dec {
                height: 35px;
                width: 100%;
                background-color: #ffffff;
                color: #d91734;
                border: 2px solid #d91734;
                @include media_query(M) {
                  width: 50%;
                }

                .gift_icon {
                  width: 20px;
                  height: 16px;
                  background-image: url(../../../styles/images/red_gift_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-right: 10px;
                }
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 700;
                  color: #d91734;
                  @include media_query(M) {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }

              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #5e5e5e;
                margin-right: 10px;
              }
              .upload_icon {
                width: 18px;
                height: 16px;
                background-image: url(../../../styles/images/upload_gray_icon.svg);
                background-repeat: no-repeat;
                background-size: contain;
              }
              .disable_state {
                width: 18px;
                height: 18px;
                background-image: url(../../../styles/images/upload_gray_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
              }
              &.inprogress_state {
                background-color: #f1f2ff;

                .txt {
                  color: #38539a;
                }
                .upload_icon {
                  width: 16px;
                  height: 16px;
                  background-image: url(../../../styles/images/inprogress_icon.svg);
                  background-repeat: no-repeat;
                  background-size: cover;
                  animation: rotation 2s infinite linear;
                }
                @keyframes rotation {
                  from {
                    transform: rotate(0deg);
                  }
                  to {
                    transform: rotate(359deg);
                  }
                }
              }
              &.final_state {
                background-color: #2c2c2c;
                .txt {
                  color: #ffffff;
                }
                .upload_icon {
                  width: 18px;
                  height: 16px;
                  background-image: url(../../../styles/images/upload_icon.svg);
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }
            }
            .darkBackground {
              background-color: #2c2c2c;
            }

            .attachement_wrapper {
              width: 100%;
              height: 50px;
              background-color: #f0f0f0;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 15px;
              margin: 15px 0;
              .txt {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #2c2c2c;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .file_wrapper {
                display: flex;
                align-items: center;
                cursor: pointer;
                .upload_icon_blue {
                  width: 18px;
                  height: 17px;
                  background-image: url(../../../styles/images/upload_icon_n.svg);
                  background-repeat: no-repeat;
                  background-size: cover;
                  margin-right: 8px;
                }
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                  line-height: 24px;
                  font-weight: 700;
                  color: #38539a;
                  border-bottom: 2px solid #38539a;
                }
              }
            }

            .checkbox_main_container {
              display: flex;
            }
            .checkbox_container {
              display: flex;
              align-items: flex-start;
              margin-right: 15px;
              &:last-child {
                margin-right: 0;
              }
              @include media_query(M) {
                // padding: 15px 0;
                align-items: center;
              }
              .checkbox_note {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
                color: #2c2c2c;
              }
              .checkbox_note_disable {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 21px;
                font-weight: 500;
                color: #707070;
              }
            }
            .certificate_wrapper {
              .title {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #2c2c2c;
                margin-bottom: 12px;
              }
              .title_disable {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #707070;
                margin-bottom: 12px;
              }
            }
            .certificate_wrapper_list {
              margin-top: 16px;
              .title {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #2c2c2c;
                margin-bottom: 12px;
              }
              .title_disable {
                font-family: 'SourceSansPro';
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                color: #707070;
                margin-bottom: 12px;
              }
              .checkbox_container_disable {
                pointer-events: none;
              }
            }
            &.audtarg_wrapper {
              .achievement_txt {
                line-height: 24px;
              }
              .italic_txt {
                font-size: 16px;
                font-weight: 500;
                font-style: italic;
                color: #2C2C2C;
              }
              .user_grp_row_wrapper {
                margin: 0 -15px;
                .user_group_container{
                  display: flex;
                  flex-direction: column;
                  @include media_query(M){
                    flex-direction: row;
                  }
                  .padding_left{
                  @include media_query(M){
                    padding-left: 40px;
                  }
                }
                  .padding_right{
                  @include media_query(M){
                    padding-right: 40px;
                    border-right: 1px solid #E4E4E4;
                  }
                  }
                }
              }
              .card_list_wrapper {
                width: 100%;
                .title {
                  font-family: 'SourceSansPro';
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  margin: 4px 0 16px;
                  @include media_query(M) {
                    font-size: 18px;
                    line-height: 21px;
                  }
                }
                .usergroup_withoutcourse_wrapper{
                  display: flex;
                  width: 100%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  
                  @include media_query(XS){
                    margin-top: 50px;
                  }
                  @include media_query(M){
                  margin-top: 0px;
                }
              }
              }
              .form_field {
                .input_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  // position: relative;

                  // below css added for disabled state
                  .disabled {
                    border: 2px solid #d9d9d9;
                  }
                  .input_disable_div {
                    border: 2px solid #d9d9d9;
                    background: #ffffff;
                  }
                  .dropdown_otr {
                    position: relative;
                    width: 80%;
                    margin-right: 15px;
                    .input {
                      width: 100%;
                      margin-right: 15px;
                      &.disabled {
                        border: 2px solid #d9d9d9;
                      }
                    }
                  }
                  .input {
                    width: 80%;
                    margin-right: 15px;
                  }
                  .add_btn {
                    font-family: 'SourceSansPro';
                    // width: 20%;
                    min-width: 115px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    color: #5e5e5e;
                    background: #d9d9d9;
                    border-radius: 24px;
                    cursor: pointer;
                    .plus_sign {
                      width: 12px;
                      height: 12px;
                      background-image: url(../../../styles/images/plus_icon.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                      margin-right: 8px;
                    }
                  }
                  .add_btn_enable {
                    font-family: 'SourceSansPro';
                    // width: 20%;
                    min-width: 115px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    color: #ffffff;
                    background: #2c2c2c;
                    border-radius: 24px;
                    cursor: pointer;
                    .plus_sign {
                      width: 12px;
                      height: 12px;
                      background-image: url(../../../styles/images/add-white.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                      margin-right: 8px;
                      color: #ffffff;

                      &path {
                        fill: #ffffff;
                      }
                    }
                  }
                  .count_num {
                    font-family: 'SourceSansPro';
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #5e5e5e;
                    margin-left: 10px;
                  }
                }
              }
              .info_wrapper {
                position: relative;
                &.margin_zero {
                  margin: 0;
                  margin-bottom: 15px; //kept for edit skills input
                }
              }

              .pills_container {
                // padding: 0;
              }
            }
            &.thumbnail_wrapper {
              .pre_browse_wrapper {
                font-family: 'SourceSansPro';
                width: 100%;
                height: 200px;
                border: 2px dashed #707070;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 25px 0;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #707070;
                margin-bottom: 15px;

                &.disabled {
                  opacity: 0.6;
                  pointer-events: none;
                }
                &.uploading_state {
                  border: 2px dashed #38539a;
                  background-color: #f1f2ff;
                  .browse_img {
                    background-image: url(../../../styles/images/browse_icon_blue1.svg);
                  }
                  .browse_text {
                    color: #38539a;
                    border-bottom: 1px solid #38539a;
                    cursor: pointer;
                  }
                  .text {
                    color: #38539a;
                  }
                }

                .browse_img_txt_wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 15px;
                  @include media_query(M) {
                    margin-bottom: 25px;
                  }
                  &:hover {
                    .browse_img {
                      background-image: url(../../../styles/images/browse_icon_blue1.svg);
                    }
                    .browse_text {
                      color: #38539a;
                      border-bottom: 1px solid #38539a;
                      cursor: pointer;
                    }
                  }
                }
                .secondary_text {
                  &:hover {
                    color: #38539a;
                    border-bottom: 1px solid #38539a;
                  }
                }

                .browse_img {
                  width: 35px;
                  height: 35px;
                  background-image: url(../../..//styles/images/browse_icon_black.svg);
                  background-repeat: no-repeat;
                  background-size: cover;
                  margin-bottom: 40px;
                  cursor: pointer;
                }
                .browse_text {
                  // text-decoration: underline;
                  border-bottom: 1px solid #707070;
                  margin-bottom: 15px;
                  cursor: pointer;
                  @include media_query(M) {
                    margin-bottom: 25px;
                  }
                  &:last-child {
                    margin: 0;
                  }
                }
                .text {
                  margin-bottom: 15px;
                  @include media_query(M) {
                    margin-bottom: 25px;
                    margin-top: 25px;
                  }
                }
                @include media_query(XS) {
                  .browse_img {
                    margin-bottom: 15px;
                  }
                  .browse_text {
                    margin-bottom: 0;
                  }
                  .browse_text_secondary {
                    display: none;
                  }
                  .text {
                    // display: none;
                  }
                }
                @include media_query(M) {
                  font-family: 'SourceSansPro';
                  font-size: 18px;
                  line-height: 21px;
                  height: 350px;
                  .browse_img {
                    // margin-bottom: 40px;
                  }
                  .browse_text {
                    margin-bottom: 20px;
                  }
                  .browse_text_secondary {
                    display: block;
                  }
                  .text {
                    display: block;
                  }
                }
                @include media_query(L) {
                  width: 100%;
                }
              }
              .thumbnail_inner_wrapper {
                margin-bottom: 15px;
                .top_section {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  @include media_query(L) {
                    margin-bottom: 15px;
                  }

                  @include media_query(L) {
                    flex-direction: row;
                    margin-bottom: 15px;
                  }
                  .btn_wrapper_uaf {
                    width: 100%;
                    background-color: #ffffff;
                    border: 2px solid #2c2c2c;

                    @include media_query(L) {
                      width: 65%;
                      margin-right: 10px;
                    }
                    .txt {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 18px;
                      color: #2c2c2c;
                      margin-right: 10px;
                      @include media_query(M) {
                        font-size: 16px;
                        line-height: 24px;
                      }
                    }
                    .book_icon {
                      width: 22px;
                      height: 14px;
                      background-image: url(../../../styles/images/book_iconN.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                    }
                  }
                  .insturction_text_wrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin: 12px 0;
                    justify-content: flex-end;
                    cursor: pointer;
                    @include media_query(L) {
                      width: 35%;
                      margin: 0;
                    }

                    .info_text {
                      font-family: 'SourceSansPro';
                      font-size: 14px;
                      font-weight: 700;
                      line-height: 18px;
                      color: #2c2c2c;
                      margin-right: 10px;
                      @include media_query(M) {
                        font-size: 16px;
                        line-height: 24px;
                      }
                    }
                    .left_arrow_icon {
                      width: 12px;
                      height: 18px;
                      background-image: url(../../../styles/images/left_arrowN.svg);
                      background-repeat: no-repeat;
                      background-size: cover;
                    }
                  }
                }
                .img_wrapper {
                  width: 100%;
                  height: 200px;
                  @include media_query(M) {
                    height: 350px;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                  }
                  .img {
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                    //background-image: url(../../../styles/images/sample_img6.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 10px;
                    @include media_query(M) {
                      height: 100%;
                      max-height: 100%;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .dropdown_container {
          position: absolute;
          width: 100%;
          top: 45px;
          background-color: #fff;
          border-radius: 10px;
          z-index: 1;
          border: 2px solid #2c2c2c;
          padding: 10px 10px 10px 0;
          .list_wrapper {
            //height: 185px;
            overflow: auto;
            .list {
              font-family: 'SourceSansPro';
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #2c2c2c;
              padding: 0 16px;
              text-transform: capitalize;
              // min-height: 35px;
              cursor: pointer;
              @include media_query(XS) {
                height: auto;
                margin-bottom: 20px;
              }
              @include media_query(M) {
                min-height: 35px;
                margin-bottom: 0;
              }
              &:hover {
                font-family: 'SourceSansPro';
                font-weight: 700;
                color: #38539a;
                background-color: #f4f4f4;
                .img_item {
                  width: 16px;
                  height: 14px;
                  background-image: url(../../../styles/images/trolly_icon.svg);
                }
              }
              &:focus {
                font-family: 'SourceSansPro';
                font-weight: 700;
                color: #38539a;
                background-color: #f4f4f4;
                outline: none;
              }
              .img_item {
                width: 10px;
                height: 17px;
                background-image: url(../../../styles/images/mobile_icon.svg);
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 10px;
              }
              .text_item {
              }
            }
          }
        }

        .course_time_wrapper {
          display: flex;
          flex-direction: column;
          &.padding_class {
            padding-top: 25px;
            @include media_query(L) {
              padding-top: 40px;
            }
          }
          @include media_query(XS) {
            padding-top: 0px;
            // margin-bottom: 40px;
          }
          // padding: 0 15px;
          @include media_query(M) {
            flex-direction: row;
            align-items: center;
            padding-top: 25px;
          }
          @include media_query(L) {
            margin-bottom: 40px;
          }
          .txt {
            font-family: 'SourceSansPro';
            font-size: 21px;
            font-weight: 700;
            color: #2c2c2c;
            margin-right: 25px;
            margin-bottom: 12px;
            @include media_query(M) {
              margin-bottom: 0;
              font-size: 24px;
            }
          }
          .time_spend_wrapper {
            font-family: 'SourceSansPro';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #2c2c2c;
            display: flex;
            align-items: center;
            .hrs_box {
              width: 60px;
              height: 35px;
              background-color: #ffffff;
              border: 2px solid #2c2c2c;
              border-radius: 12px;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px;
              padding-left: 10px;
              text-align: center;
            }
            .txt_xs {
            }
            input[type='number']::-webkit-inner-spin-button {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
        .note_text {
          font-family: 'SourceSansPro';
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #2c2c2c;
          margin-bottom: 10px;
        }
      }

      &.category_wrapper {
        @include media_query(L) {
          padding-top: 40px;
          height: calc(100vh - 150px);
        }
      }

      &.edit_course_wrapper {
        @include media_query(XS) {
          padding-bottom: 0px;
          padding-top: 0;
        }
        .row_wrapper {
          @include media_query(M) {
            margin: 0 -40px;
          }
          .info_container {
            .inr_wrapper {
              margin-bottom: 25px;
              &.margin_zero {
                margin-bottom: 0;
                padding-top: 0;
              }
              &.margin_class {
                margin-bottom: 15px;
              }
              @include media_query(M) {
                margin-bottom: 25px;
              }
              @include media_query(L) {
                margin-bottom: 40px;
              }
              .astric {
                position: relative;
                top: -3px;
              }
              .title_wrapper {
                &.padding_class {
                  padding-top: 10px;
                  @include media_query(L) {
                    padding-top: 25px;
                  }
                }
                &.invisible_tt {
                  visibility: hidden;
                }
                .title {
                  display: flex;
                  align-items: center;
                  color: #38539a;
                  position: relative;
                  @include media_query(M) {
                    margin-bottom: 16px;
                  }
                  .icon {
                    width: 25px;
                    height: 25px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 10px;

                    &.setting_icon {
                      width: 25px;
                      height: 20px;
                      background-image: url(../../../styles/images/setting_icon.svg);
                    }

                    &.timer_icon {
                      width: 26px;
                      height: 24px;
                      background-image: url(../../../styles/images/timer_icon.svg);
                    }
                    &.publish_icon {
                      width: 23px;
                      height: 24px;
                      background-image: url(../../../styles/images/publish_icon.svg);
                    }
                    &.info_icon {
                      width: 26px;
                      height: 24px;
                      background-image: url(../../../styles/images/info_icon.svg);
                    }
                    &.user_icon {
                      width: 27px;
                      height: 20px;
                      background-image: url(../../../styles/images/user_icon.svg);
                    }
                    &.achievements_icon {
                      width: 20px;
                      height: 28px;
                      background-image: url(../../../styles/images/achievements_icon.svg);
                    }

                    &.thumbnail_icon {
                      width: 25px;
                      height: 20px;
                      background-image: url(../../../styles/images/thumbnail_icon.svg);
                    }

                    &.content_book_icon {
                      width: 22px;
                      height: 26px;
                      background-image: url(../../../styles/images/content_book_iocn.svg);
                    }
                    &.tag_icon {
                      width: 18px;
                      height: 18px;
                      background-image: url(../../../styles/images/tag_icon.svg);
                    }
                    &.skills_icon {
                      width: 22px;
                      height: 24px;
                      background-image: url(../../../styles/images/skills_icon.svg);
                    }
                    &.warn_icon {
                      width: 20px;
                      height: 24px;
                      background-image: url(../../../styles/images/warn_icon.svg);
                    }
                  }
                }
                .arw_blue_icon {
                  position: absolute;
                  top: 10px;
                  right: 0px;
                  width: 20px;
                  height: 12px;
                  background-image: url(../../../styles/images/blue_arw_icon.svg);
                  background-repeat: no-repeat;
                  background-size: cover;
                  transition: 0.3s ease-in;
                  cursor: pointer;
                  @include media_query(M) {
                    right: 35px;
                  }
                  @include media_query(L) {
                    right: 0;
                  }

                  &.is_decommission_open {
                    transform: rotate(180deg);
                  }
                }
              }
              .suggesion_box {
                background-color: #f1f2ff;
                border-radius: 12px;
                width: 100%;
                min-height: 40px;
                padding: 0 10px;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 14px;
                  color: #38539a;
                  font-weight: 400;
                  line-height: 18px;
                }
                .bold_text {
                  font-family: 'SourceSansPro';
                  font-weight: 700;
                }
              }
              .warning_section {
                display: flex;
                background: #fff9f0;
                border: 1px solid #d1b278;
                border-radius: 10px;
                padding: 10px;
                // margin-bottom: 15px;

                @include media_query(M) {
                  // display: none;
                }
                .message_section {
                  padding-left: 5px;

                  .mb_5 {
                    margin-bottom: 5px;
                  }

                  .heading {
                    font-family: 'SourceSansPro';
                    font-size: 14px;
                    font-weight: 400;
                    color: #a36a00;
                  }
                }
              }

              .course_status {
                width: 100%;
                height: 65px;
                padding: 0 15px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 25px;
                @include media_query(M) {
                  margin-bottom: 40px;
                  padding: 0 25px;
                }

                &.archive {
                  background-color: #fdf4f4;
                  .txt_wrapper {
                    .status_txt {
                      color: #d91734;
                    }
                  }
                  .status_btn {
                    display: none;
                  }
                }

                &.draft {
                  background-color: #fff9f0;
                  .txt_wrapper {
                    .status_txt {
                      color: #a36a00;
                    }
                  }
                }

                &.offline {
                  background-color: #f0f0f0;
                  .txt_wrapper {
                    .status_txt {
                      color: #62625a;
                    }
                  }
                }
                &.live {
                  background-color: #f0fae5;
                  .txt_wrapper {
                    .status_txt {
                      color: #107f47;
                    }
                  }
                  .status_btn {
                    display: flex;
                  }
                }

                @include media_query(M) {
                  min-height: 72px;
                }

                .txt_wrapper {
                  display: inline-block;
                  margin-right: 15px;
                  .txt {
                    font-family: 'SourceSansPro';
                    display: inline;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 500;
                    color: #2c2c2c;
                    margin-right: 8px;
                    @include media_query(M) {
                      font-size: 24px;
                      line-height: 28px;
                      font-weight: 400;
                    }
                  }
                  .status_txt {
                    font-family: 'SourceSansPro';
                    display: inline;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 18px;

                    @include media_query(M) {
                      font-size: 24px;
                      line-height: 24px;
                    }
                  }
                }

                .status_btn {
                  font-family: 'SourceSansPro';
                  width: 120px;
                  height: 35px;
                  background: #ffffff;
                  border: 2px solid #2c2c2c;
                  box-sizing: border-box;
                  border-radius: 24px;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  color: #2c2c2c;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  margin-left: 5px;
                  @include media_query(M) {
                    min-width: 200px;
                    height: 50px;
                    display: flex;
                  }
                  &:hover {
                    color: #ffffff;
                    background-color: #2c2c2c;
                  }
                }
              }
              .course_action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 40px;

                .txt {
                  font-family: 'SourceSansPro';
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                  color: #2c2c2c;
                  margin-right: 10px;
                }
                .archive_black_icon {
                  background-image: url(../../../styles/images/archive_black.svg);
                  width: 20px;
                  height: 18px;
                  background-repeat: no-repeat;
                  background-size: cover;
                }
              }
              .upload_details_wrapper {
                width: 100%;
                display: flex;
                justify-content: space-around;
                // margin-bottom: 25px;

                .upload_info {
                  flex: 33.33%;
                  max-width: 33.33%;
                  display: flex;
                  flex-direction: column;
                  // align-items: center;
                  border-left: 1px solid #e4e4e4;
                  padding-left: 15px;
                  padding-right: 15px;
                  @include media_query(M) {
                    padding-left: 50px;
                    padding-right: 50px;
                  }
                  &:first-child {
                    align-items: flex-start;
                    border-left: none;
                    padding-left: 0;
                    @include media_query(M) {
                      padding-right: 50px;
                    }
                  }
                  &:last-child {
                    padding-right: 0;
                  }

                  .title {
                    font-family: 'SourceSansPro';
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 18px;
                    color: #2c2c2c;
                    margin-bottom: 10px;
                    @include media_query(M) {
                      font-size: 16px;
                      line-height: 24px;
                    }
                  }
                  .info_text {
                    font-family: 'SourceSansPro';
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #707070;
                    @include media_query(M) {
                      font-size: 16px;
                      line-height: 24px;
                    }
                  }
                }
              }
            }
          }
          .course_time_wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            @include media_query(M) {
              padding: 0px;
              flex-direction: row;
              align-items: center;
            }
            .txt {
              font-family: 'SourceSansPro';
              font-size: 18px;
              line-height: 24px;
              @include media_query(M) {
                margin-bottom: 0;
              }
            }
            .time_spend_wrapper {
              font-family: 'SourceSansPro';
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #2c2c2c;
              display: flex;
              align-items: center;
              .hrs_box {
                width: 60px;
                height: 35px;
                background-color: #ffffff;
                border: 2px solid #2c2c2c;
                border-radius: 12px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                padding-left: 10px;
                text-align: center;
              }
              .txt_xs {
              }
              input[type='number']::-webkit-inner-spin-button {
                opacity: 0;
                pointer-events: none;
              }
            }
          }
          .form_field {
            margin-bottom: 8px;
            @include media_query(M) {
              margin-bottom: 16px;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    
    .form_field {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      &.form_field_margin {
        margin-bottom: 35px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .label {
        font-family: 'SourceSansPro';
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #2c2c2c;
        margin-bottom: 8px;
        @include media_query(M) {
          font-size: 18px;
          margin-bottom: 12px;
        }
      }
      .label_wrapper {
        font-family: 'SourceSansPro';

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        @include media_query(M) {
          margin-bottom: 12px;
        }
        .label_text {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #2c2c2c;
          white-space: nowrap;
          @include media_query(M) {
            font-size: 18px;
          }
        }
        .tag_text {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #707070;

          @include media_query(M) {
            font-size: 16px;
          }
        }
        @include media_query(M) {
          font-size: 21px;
        }
      }
      .input_wrapper {
        position: relative;
        &.input_capitalize {
          // text-transform: capitalize;
        }
        .input {
          font-family: 'SourceSansPro';
          width: 100%;
          height: 35px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #2c2c2c;
          padding: 0 12px;
          display: flex;
          align-items: center;
          //text-transform: capitalize;
          &::placeholder {
            color: #707070;
            font-weight: 200;
          }
          @include media_query(M) {
            font-size: 16px;
            line-height: 21px;
          }
        }
        .textarea {
          width: 100%;
          height: 70px;
          border: 2px solid #2c2c2c;
          border-radius: 10px;
          font-family: 'SourceSansPro';
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #2c2c2c;
          padding: 10px 12px;
          &::placeholder {
            color: #707070;
            font-weight: 200;
          }
          @include media_query(M) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .down_arw_icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          width: 17px;
          height: 12px;
          background-image: url(../../../styles/images/down_arrow_2.svg);
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .down_arw_icon_open {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
          right: 10px;
          width: 17px;
          height: 12px;
          background-image: url(../../../styles/images/down_arrow_2.svg);
          background-repeat: no-repeat;
          background-size: contain;
          cursor: pointer;
        }
      }
    }
    .error_wrapper_max_char {
      .input_wrapper {
        input, textarea {
          border: 2px solid #d91734 !important;
          padding: 0 35px 0 12px !important;
        }
      }
      
      .error_icon_red {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        width: 20px;
        height: 20px;
        background-image: url('../../../styles/images/error_icon_red.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
      .error_message {
        color: #d91734 !important;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 8px;
      }
    }
    .error_wrapper {
      &.form_field {
        .input_wrapper {
          font-family: 'SourceSansPro';
          .input {
            border: 2px solid #d91734;
          }
          .error_icon_red {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            width: 20px;
            height: 20px;
            background-image: url(../../../styles/images/error_icon_red.svg);
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }

      .error_message {
        font-family: 'SourceSansPro';
        color: #d91734;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 10px 0;
      }
    }
    .error_wrapper_max_char {
      &.form_field {
        .input_wrapper {
          font-family: 'SourceSansPro';
          .input {
            border: 2px solid #2c2c2c;
          }
        }
      }

      .error_message_max_char {
        font-family: 'SourceSansPro';
        color: #d91734;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 10px 0;
      }
    }
  }
}

// Modal Starts
.modal_wrapper {
  // position: fixed;
  // display: flex;
  // align-items: center;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // background: rgba(112, 112, 112, 0.7);
  // z-index: 10;
  // .delete_modal {
  //   display: flex;
  //   flex-direction: column;
  //   position: relative;
  //   margin: auto;
  //   background-color: #fff;
  //   padding: 20px 30px 15px 30px;
  //   @include media_query(XS) {
  //     min-width: 315px;
  //     min-height: 230px;
  //     max-width: 100%;
  //     max-height: 400px;
  //   }
  //   @include media_query(M) {
  //     min-width: 390px;
  //     min-height: 230px;
  //     max-width: 400px;
  //     max-height: 400px;
  //   }
  .header_main_wrapper {
    display: flex;
    align-items: center;
    height: 75px;
    box-shadow: 0px 2px 16px rgba(44, 44, 44, 0.1);
    padding: 0 30px;
    margin: -20px -30px 16px -30px;
    .header_wrapper {
      padding: 0 10px 0px 0;
      .heading {
        font-family: 'SourceSansPro';
        font-size: 24px;
        font-weight: 700;
        color: #2c2c2c;
      }
    }
  }

  .close_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background-color: #2c2c2c;
    cursor: pointer;

    .close_icon {
      width: 10px;
      height: 10px;
      background-image: url(../../../styles/images/close-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .warning_section {
    display: flex;
    background: #fff9f0;
    border: 1px solid #d1b278;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 30px;

    .warning_icon {
      width: 18px;
      height: 18px;
      background-image: url(../../../styles/images/warning-icon.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }

    .message_section {
      padding-left: 5px;

      .mb_5 {
        margin-bottom: 5px;
      }

      .heading {
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 700;
        color: #a36a00;
      }

      .sub_heading {
        font-family: 'SourceSansPro';
        font-size: 14px;
        color: #a36a00;
      }
    }
  }

  .confirm_wrapper {
    padding-bottom: 45px;

    .txt {
      font-family: 'SourceSansPro';
      font-size: 14px;
      color: #2c2c2c;
      margin-bottom: 10px;
    }

    .input_wrapper {
      position: relative;
      .input {
        width: 100%;
        height: 35px;
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #2c2c2c;
        padding: 0 10px;
        border: 2px solid #2c2c2c;
        border-radius: 10px;
        &.error_input {
          border: 2px solid #d91734;
        }
        &::placeholder {
          font-family: 'SourceSansPro';
          font-weight: 200;
          color: #707070;
        }
      }
      .error_icon {
        display: flex;
        align-items: center;
        transform: translate(-50%, 25%);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 23px;
        height: 23px;
        background-image: url(../../../styles/images/insufficient.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
      .error_msg {
        font-family: 'SourceSansPro';
        font-size: 14px;
        font-weight: 500;
        color: #d91734;
        margin-top: 8px;
      }
    }
  }

  .confirm_msg {
    text-align: center;

    .txt {
      display: inline-block;
      vertical-align: middle;
      font-family: 'SourceSansPro';
      font-size: 16px;
      color: #2c2c2c;
    }

    .bold {
      font-family: 'SourceSansPro';
      font-weight: 700;
    }
  }

  .btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    .btn {
      min-width: 120px;
      height: 35px;
      font-family: 'SourceSansPro';
      font-size: 16px;
      font-weight: 700;
      border: 2px solid #2c2c2c;
      background-color: #fff;
      border-radius: 24px;
      margin: 0 6px;
      padding: 0 30px;
      cursor: pointer;

      &.active {
        background-color: #d91734;
        border: 2px solid transparent;
        color: #fff;
      }

      &.save_changes {
        background-color: #2c2c2c;
        border: 2px solid transparent;
        color: #fff;
      }

      &.disabled {
        pointer-events: none;
        background-color: #d9d9d9;
        color: #5e5e5e;
        border: 2px solid transparent;
      }
    }
  }
  // }
}
// Modal Ends

.search_list_wrapper {
  width: 100%;
}
.dropdown_otr {
  position: relative;
  width: 80%;
  margin-right: 15px;
  .input {
    width: 100%;
    margin-right: 15px;
  }
}

.bold_txt {
  font-family: 'SourceSansPro';
  font-weight: 700;
  display: block;
  @include media_query(M) {
    display: none;
  }
}
.disable {
  font-family: 'SourceSansPro';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  @include media_query(M) {
    font-size: 16px;
    line-height: 24px;
  }
}

.input_arrow_dropdown_wrapper {
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  .input_wrapper_and_arrow {
    display: flex;
    width: 120%;
    .input_add {
      width: 120%;
      margin-right: 15px;
    }
    .down_arw_icon_add {
      position: relative;
      transform: translateY(-50%);
      right: 50px;
      top: 20px;
      width: 17px;
      height: 12px;
      background-image: url(../../../styles/images/down_arrow_2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    .down_arw_icon_open_add {
      transform: translateY(-50%) rotate(180deg);
      position: relative;
      right: 50px;
      top: 20px;
      width: 17px;
      height: 12px;
      background-image: url(../../../styles/images/down_arrow_2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
  .dropdown_container_add {
    position: absolute;
    width: 100%;
    top: 45px;
    right: 0px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    border: 2px solid #2c2c2c;
    padding: 10px 10px 10px 0;
    .list_wrapper {
      //height: 185px;
      overflow: auto;
      .list {
        font-family: 'SourceSansPro';
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #2c2c2c;
        padding: 0 16px;
        text-transform: capitalize;
        // min-height: 35px;
        cursor: pointer;
        @include media_query(XS) {
          height: auto;
          margin-bottom: 20px;
        }
        @include media_query(M) {
          min-height: 35px;
          margin-bottom: 0;
        }
        &:hover {
          font-family: 'SourceSansPro';
          font-weight: 700;
          color: #38539a;
          background-color: #f4f4f4;
          .img_item {
            width: 16px;
            height: 14px;
            background-image: url(../../../styles/images/trolly_icon.svg);
          }
        }
        &:focus {
          font-family: 'SourceSansPro';
          font-weight: 700;
          color: #38539a;
          background-color: #f4f4f4;
          outline: none;
        }
        .img_item {
          width: 10px;
          height: 17px;
          background-image: url(../../../styles/images/mobile_icon.svg);
          background-repeat: no-repeat;
          background-size: cover;
          margin-right: 10px;
        }
        .text_item {
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e4e4e4;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
.main_wrapper_empty_lp {
  @include media_query(XS) {
    padding: 15px 0px 0 0px;
  }
  @include media_query(M) {
    padding: 170px 40px 0 105px;
  }
  @include media_query(L) {
    padding: 130px 40px 0 105px;
  }
  .emptyLp_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    // height: 80vh;
    padding: 0px 20px;
    @include media_query(XS) {
      height: 60vh;
    }
    @include media_query(M) {
      height: 80vh;
    }
    .sequencing_empty_img {
      display: flex;
      @include media_query(XS) {
        width: 160px;
        height: 160px;
        svg {
          height: 100%;
        }
      }
      @include media_query(M) {
        width: auto;
        height: auto;
      }
    }
    .sequencing_empty_text_header {
      font-style: normal;
      font-weight: 700;
      font-family: 'SourceSansPro';
      line-height: 28px;
      color: #2c2c2c;
      height: 28px;

      @include media_query(XS) {
        font-size: 18px;
        margin-top: 20px;
      }
      @include media_query(M) {
        font-size: 24px;
        margin-top: 20px;
      }
      @include media_query(L) {
        margin-top: 20px;
      }
    }
    .sequencing_empty_text_subheader {
      display: flex;
      font-style: normal;
      font-family: 'SourceSansPro';
      font-weight: 400;
      line-height: 24px;
      color: #2c2c2c;

      margin-top: 4px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      @include media_query(XS) {
        font-size: 14px;
      }
      @include media_query(M) {
        font-size: 16px;
      }
      div {
        @include media_query(XS) {
          font-family: 'SourceSansPro';
          font-size: 14px;
          color: #2c2c2c;
        }
        @include media_query(M) {
          font-size: 16px;
          line-height: 24px;
          color: #2c2c2c;
        }
      }
      @include media_query(XS) {
        margin-top: 12px;
        // height: 72px;
        text-align: center;
      }
      @include media_query(M) {
        margin-top: 6px;
        // height: 72px;
      }
      @include media_query(L) {
        height: 48px;
      }
    }
    .sequencing_empty_text_button_wrapper {
      cursor: pointer;
    }
    .sequencing_empty_text_button {
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-family: 'SourceSansPro';
      font-size: 16px;
      line-height: 24px;
      color: #2c2c2c;
      margin-top: 12px;
    }
    .sequencing_empty_text_button_line {
      width: 124px;
      height: 2px;
      background: #2c2c2c;
      border-radius: 100px;
      margin-top: 2px;
    }
  }
}

.checkbox_container_disable {
  pointer-events: none;
}
.card_shimmer {
  height: 100px;
  //background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%) !important;
}

.emptyLp_wrapper_shimmer {
  height: 50vh !important;
}
.tags_shimmer {
  height: 40px;
  width: 100%;
}
.category_shimmer {
  width: 200px;
  height: 40px;
  margin-right: 16px;
  border-radius: 20px;
}
